import React from 'react';
import Menu, { MenuItem } from 'components/Menu';
import { LogoutButton } from './LogoutButton';
import BaseIcon from 'components/Icon';
import gtag from 'ga-gtag';
import { useRouter } from 'next/router';
import { _ClearStorage } from '@netfront/gelada-react-shared';
import { IconId } from 'components/Icons/Icons';
import cx from 'classnames';

import styles from './SiteMenu.module.css';

function Icon({ id }: { id: IconId }) {
  return <BaseIcon id={id} className={styles['SiteMenu--Icon']} size="md" fill="aqua" stroke="none" />;
}

export function SiteMenu() {
  const router = useRouter();

  const handleLogOut = () => {
    _ClearStorage();
    gtag('set', { user_id: '' });
    // onClose();
    router.push('/');
  };

  return (
    <Menu
      className={cx(styles['SiteMenu'])}
      activatorClassName={styles['SiteMenu--activator']}
      mount="right"
      title="Menu"
      footer={<LogoutButton onClick={handleLogOut} className={styles['SiteMenu--LogoutButton']} />}
      buttonText="Menu"
    >
      <MenuItem href="/dashboard">
        <Icon id="home" />
        Dashboard
      </MenuItem>
      <MenuItem href="/calendar">
        <Icon id="book_alt" />
        Calendar
      </MenuItem>
      <MenuItem href="/bookmarks">
        <Icon id="bookmark" />
        Bookmarks
      </MenuItem>
      <MenuItem href="/toolbox">
        <Icon id="breifcase_alt" />
        Toolbox
      </MenuItem>
      <MenuItem href="/profile">
        <Icon id="profile_alt" />
        Profile
      </MenuItem>
      <MenuItem href="/get-help">
        <Icon id="phone" />
        Get help now
      </MenuItem>
    </Menu>
  );
}
