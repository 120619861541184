import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './Content.module.css';

export interface ContentProps {
  children: ReactNode;
  className?: string;
}

export function Content({ children, className }: ContentProps) {
  return <div className={cx(styles['Content'], className)}>{children}</div>;
}
