import { useEffect, useState } from 'react';
import { _GetAccessToken } from '@netfront/gelada-react-shared';

export function useIsAuthenticated() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    setIsAuth(Boolean(_GetAccessToken()));
  });

  return isAuth;
}
