import React, { ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import cx from 'classnames';
import styles from './Link.module.css';

export interface LinkProps extends NextLinkProps {
  children: ReactNode;
  isDisabled?: boolean;
  isExternal?: boolean;
  className?: string;
  onClick?: () => void;
}

export function Link({ children, href, as, isDisabled, isExternal, className, passHref = true, onClick }: LinkProps) {
  if (isDisabled) return <>{children}</>;
  if (isExternal) {
    return (
      <a
        className={cx(styles['Link'], className)}
        href={href as string}
        rel="noopener noreferrer"
        target="_blank"
        onClick={onClick}
      >
        {children}
      </a>
    );
  }
  return (
    <NextLink passHref={passHref} href={href} as={as}>
      <span className={cx(styles['Link'], className, 'NextLink')} onClick={onClick}>
        {children}
      </span>
    </NextLink>
  );
}
