import React, { ReactNode, useState } from 'react';
import Card from 'components/Card';
import cx from 'classnames';
import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from './Menu.module.css';
import cardStyles from 'components/Card/Card.module.css';

export interface MenuProps {
  children: ReactNode;
  mount?: 'left' | 'right';
  title: ReactNode;
  footer?: ReactNode;
  menuClassName?: string;
  className?: string;
  activatorClassName?: string;
  closerClassName?: string;
  openStateHandler?: (isOpen: boolean) => void;
  isOpen?: boolean;
  transparentTitle?: boolean;
  buttonText?: string;
}

export interface ActionButtonProps {
  onClick?: () => void;
}

export function Menu({
  children,
  mount,
  title,
  footer,
  menuClassName,
  className,
  activatorClassName,
  openStateHandler,
  isOpen,
  transparentTitle,
  buttonText,
}: MenuProps) {
  const [isMenuOpen, setIsOpen] = useState<boolean>();
  if (isMenuOpen || isOpen) {
    return (
      <div className={cx(styles['container'], className, 'no-print')}>
        <Card
          contentClassName={styles['Menu--card']}
          transparentTitle={transparentTitle}
          className={cx(styles['Menu'], mount && styles[`Menu--mount-${mount}`], menuClassName)}
          footer={footer}
          header={
            <div className={styles['header']}>
              <h1>{title}</h1>
              <Button
                className={styles['closer']}
                onClick={() => {
                  openStateHandler?.(false);
                  setIsOpen(false);
                }}
              >
                <Icon id="cross" size="lg" />
              </Button>
            </div>
          }
          
        >
          <ul className={styles['Menu--ul']}>{children}</ul>
        </Card>
      </div>
    );
  } else if (!openStateHandler) {
    return (
      <div className={cx('no-print', activatorClassName)}>
        <Button className={styles['activator']} onClick={() => setIsOpen(true)}>
          {buttonText || <Icon id="hamburger" size="lg" />}
        </Button>
      </div>
    );
  }

  return <></>;
}
