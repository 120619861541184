import React from 'react';

import Button from 'components/Button';

export interface LogoutButtonProps {
  className?: string;
  onClick: () => void;
}

export function LogoutButton({ className, onClick }: LogoutButtonProps) {
  return (
    <Button className={className} color="tertiary" style="rounded" onClick={onClick}>
      Log out
    </Button>
  );
}
