import React, { ReactNode } from 'react';
import cx from 'classnames';
import { Title } from './Title';
import { Footer } from './Footer';

import styles from './Card.module.css';

export interface CardProps {
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  transparentTitle?: boolean;
  transparent?: boolean;
  className?: string;
  noShadow?: boolean;
  contentClassName?: string;
}

export function Card({ className, children, header, footer, transparent = false, transparentTitle = false, noShadow = false, contentClassName }: CardProps) {
  return (
    <div
      className={cx(
        styles['Card--wrapper'],
        noShadow && styles['no-shadow'],
        (transparentTitle || transparent) ? styles['Card--wrapper--transparent'] : styles['Card--wrapper--white'],
        className,
      )}
    >
      {header && <Title className={(transparentTitle || transparent) ? styles['Card--Title--transparent'] : styles['Card--Title--white']}>{header}</Title>}
      <div
        className={cx(
          styles['Card--content'],
          transparent ? styles['Card--content--transparent'] : styles['Card--content--white'],
          transparentTitle && styles['Card--content--transparent-title'],
          contentClassName && contentClassName
        )}
      >
        {children}
        {footer && <Footer>{footer}</Footer>}
      </div>
    </div>
  );
}
