import React, { ReactNode } from 'react';
import styles from './Footer.module.css';

export interface FooterProps {
  children: ReactNode;
}

export function Footer({ children }: FooterProps) {
  return <div className={styles['Card--Footer']}>{children}</div>;
}
