import cx from 'classnames';
import cardStyles from 'components/Card/Content.module.css';
import Link from 'components/Link';
import React, { ReactNode } from 'react';
import styles from './MenuItem.module.css';

export interface MenuItemProps {
  as?: string;
  children: ReactNode;
  className?: string;
  href: string;
  toggle?: ReactNode;
  onClick?: () => void;
}

export function MenuItem({ as, children, className, href, toggle, onClick }: MenuItemProps) {
  return (
    <li className={cx(styles['MenuItem--li'], className)}>
      <Link passHref href={href} as={as} className={cx(styles['MenuItem--a'], cardStyles['Content'])} onClick={onClick}>
        {children}
      </Link>
      {toggle}
    </li>
  );
}
