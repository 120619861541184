import React from 'react';
import Link from 'components/Link';
import cx from 'classnames';

import styles from './Footer.module.css';

export function Footer() {
  return (
    <footer className={cx('no-print', styles['Footer'])}>
      <div className={styles['Footer--content']}>
        <p>© Shade</p>
        <Link href="/contact">Contact</Link>
        <Link href="/privacy">Privacy</Link>
        <Link href="/terms-of-use">Terms</Link>
        <Link isExternal href="https://netfront.com.au/">
          Site by Netfront
        </Link>
      </div>
    </footer>
  );
}
