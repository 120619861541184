import React from 'react';
import Link from 'components/Link';
import Icon, { Logo } from 'components/Icon';
import SiteMenu from 'components/SiteMenu';
import { useHomeRoute } from 'lib/hooks/useHomeRoute';
import cx from 'classnames';
import styles from './Header.module.css';
import Button from 'components/Button';
import { useRouter } from 'next/router';

export interface HeaderProps {
  showMenu?: boolean;
}

export function Header({ showMenu }: HeaderProps) {
  const homeRoute = useHomeRoute();
  const { route } = useRouter();
  return (
    <header className={cx('no-print', styles['Header'])}>
      <div className={styles['Header--Logo']}>
        <Link href={homeRoute}>
          <Logo />
        </Link>
      </div>
      <div className={styles['Header--help']}>
        <Link href="/get-help">
          <Icon
            className={styles['Header--help--Icon']}
            id="phone"
            size="md"
            hasBorder
            borderColor="white"
            backgroundColor="blue"
            borderStyle="thick"
            fill="white"
            stroke="none"
          />
        </Link>
      </div>
      {showMenu ? (
        <SiteMenu />
      ) : route === '/login' ? null : (
        <div style={{ marginLeft: 'auto' }}>
          <Button component="link" style="rounded" link={{ href: '/login' }}>
            Login
          </Button>
        </div>
      )}
    </header>
  );
}
