import React from 'react';

import './Icon.module.css';

export function Logo() {
  return (
    <div style={{ width: '5rem', height: '3rem' }}>
      <svg aria-hidden="true" id="logo" height="3rem" width="5rem" viewBox="0 0 96.66 55.444">
        <g fill="#fff">
          <g transform="translate(61.656 3.35)">
            <path
              fill="#f7b06b"
              d="M347.238,176.487a1.247,1.247,0,0,1-1.247-1.247v-4.882a1.247,1.247,0,0,1,2.493,0v4.882A1.247,1.247,0,0,1,347.238,176.487Z"
              transform="translate(-345.991 -169.111)"
            />
            <g transform="translate(13.832 4.36)">
              <path
                fill="#f7b06b"
                d="M412.138,196.285a1.247,1.247,0,0,1-1.078-1.87l2.439-4.225a1.247,1.247,0,0,1,2.159,1.246l-2.439,4.225A1.246,1.246,0,0,1,412.138,196.285Z"
                transform="translate(-410.893 -189.568)"
              />
            </g>
            <g transform="translate(24.252 16.888)">
              <path
                fill="#f7b06b"
                d="M461.034,253.191a1.247,1.247,0,0,1-.6-2.34l4.277-2.347a1.247,1.247,0,1,1,1.2,2.186l-4.277,2.347A1.24,1.24,0,0,1,461.034,253.191Z"
                transform="translate(-459.786 -248.351)"
              />
            </g>
            <g transform="translate(27.632 33.747)">
              <path
                fill="#f7b06b"
                d="M481.771,330.161l-.055,0-4.879-.213a1.246,1.246,0,0,1-1.191-1.3,1.233,1.233,0,0,1,1.3-1.191l4.879.213a1.247,1.247,0,0,1-.053,2.492Z"
                transform="translate(-475.644 -327.454)"
              />
            </g>
            <g transform="translate(23.557 47.042)">
              <path
                fill="#f7b06b"
                d="M461.927,394.891a1.24,1.24,0,0,1-.652-.185l-4.156-2.558a1.246,1.246,0,0,1,1.307-2.123l4.156,2.558a1.246,1.246,0,0,1-.655,2.308Z"
                transform="translate(-456.526 -389.84)"
              />
            </g>
          </g>
          <g transform="translate(62.422 15.583)">
            <g transform="translate(0)">
              <path
                className="a"
                fill="#f7b06b"
                d="M350,227.838l3.493,12.214.01.036.01.036A31.311,31.311,0,0,0,365.455,257.4l2.894,2.08a21.553,21.553,0,0,0-18.281-32.974c-.161,0-.32.009-.48.012C349.735,226.956,349.876,227.395,350,227.838Z"
                transform="translate(-349.587 -226.507)"
              />
            </g>
          </g>
          <g transform="translate(0 17.868)">
            <path
              fill="#d4f2f5"
              d="M90.519,256.748l-2.247-7.855A16.1,16.1,0,1,0,72.8,269.435H99.286A22.932,22.932,0,0,1,90.519,256.748Z"
              transform="translate(-56.693 -237.231)"
            />
          </g>
          <g transform="translate(12.13)">
            <path
              fill="#68d3d9"
              d="M117.58,166.83a20.335,20.335,0,0,1,12.464,4.218,20.684,20.684,0,0,1,7.271,10.654l2.246,7.855.01.036.01.036a18.553,18.553,0,0,0,7.074,10.238l5,3.6h25.525a35.657,35.657,0,0,1-13.632-19.727l-3.493-12.214a25.035,25.035,0,0,0-46.451-4.3A20.542,20.542,0,0,1,117.58,166.83Z"
              transform="translate(-113.608 -153.39)"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
