import Head from 'next/head';
import React from 'react';

interface SEOProps {
  title: string;
  description?: string;
}

const SEO = ({ title, description }: SEOProps) => {
  return (
    <Head>
      <title>{title}</title>
      <meta itemProp="name" content={title} />
      {description && <meta name="description" content={description} />}
      {description && <meta itemProp="description" content={description} />}
    </Head>
  );
};

export { SEO };
