import { useEffect, useState } from 'react';
import { _GetAccessToken } from '@netfront/gelada-react-shared';

export function useHomeRoute() {
  const [homeRoute, setHomeRoute] = useState('/');

  useEffect(() => {
    setHomeRoute(_GetAccessToken() ? '/dashboard' : '/');
  });

  return homeRoute;
}
