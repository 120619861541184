export type ColorType = 'blue' | 'aqua' | 'white' | 'none' | 'yellow' | 'green' | 'grey';

export const colorMap = {
  aqua: '#68d3d9',
  blue: '#273158',
  white: '#fff',
  none: 'none',
  yellow: '#f7b06b',
  green: '#48ddc4',
  grey: '#b6bbc1',
};
