import React from 'react';
import cx from 'classnames';
import { colorMap, ColorType } from 'consts/colors';
import { IconId } from 'components/Icons/Icons';
import styles from './Icon.module.css';

export interface IconProps {
  className?: string;
  id: IconId;
  size?: 'sm' | 'md' | 'lg';
  hasBorder?: boolean;
  rotate?: '0' | '90' | '180' | '270';
  animate?: boolean;
  fill?: ColorType;
  stroke?: ColorType;
  borderColor?: ColorType;
  backgroundColor?: ColorType;
  borderStyle?: 'normal' | 'thick';
}

/**
 * Used to insert an SVG icon into other components.
 */
export function Icon({
  className,
  id,
  size = 'lg',
  hasBorder,
  rotate = '0',
  animate,
  fill = 'none',
  stroke = 'white',
  borderColor = 'blue',
  backgroundColor = 'none',
  borderStyle = 'normal',
}: IconProps) {
  let sizeValue;
  switch (size) {
    case 'sm':
      sizeValue = '1rem';
      break;
    case 'md':
      sizeValue = '1.4rem';
      break;
    case 'lg':
      sizeValue = '1.8rem';
      break;
  }

  return (
    <div
      className={cx(
        styles['Icon--wrapper'],
        styles[`Icon--${size}`],
        hasBorder && styles['Icon--border'],
        hasBorder && styles[`Icon--border--size-${size}`],
        hasBorder && styles[`Icon--border--color-${borderColor}`],
        hasBorder && styles[`Icon--border--style-${borderStyle}`],
        hasBorder && styles[`Icon--background--color-${backgroundColor}`],
        className,
      )}
    >
      <svg
        fill={colorMap[fill]}
        stroke={colorMap[stroke]}
        aria-hidden="true"
        height={sizeValue}
        width={sizeValue}
        style={{ transform: `rotate(${rotate}deg)`, transition: animate && 'transform 0.5s' }}
      >
        <use xlinkHref={`#${id}`} />
      </svg>
    </div>
  );
}
