import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './Title.module.css';

export interface TitleProps {
  children: ReactNode;
  className?: string;
}

export function Title({ children, className }: TitleProps) {
  return <div className={cx(styles['Title'], className)}>{children}</div>;
}
